exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-download-index-jsx": () => import("./../../../src/pages/download/index.jsx" /* webpackChunkName: "component---src-pages-download-index-jsx" */),
  "component---src-pages-download-thanks-jsx": () => import("./../../../src/pages/download/thanks.jsx" /* webpackChunkName: "component---src-pages-download-thanks-jsx" */),
  "component---src-pages-fund-jsx": () => import("./../../../src/pages/fund.jsx" /* webpackChunkName: "component---src-pages-fund-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-templates-markdown-jsx": () => import("./../../../src/templates/markdown.jsx" /* webpackChunkName: "component---src-templates-markdown-jsx" */)
}

